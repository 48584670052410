<template>
  <div class="data-table">
    <div class="table-caption">{{ caption }}</div>
    <vs-table pagination :data="data">
      <template slot="thead">
        <vs-th :key="i" v-for="(header, i) in headerFields">{{ header }}</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr :data="row" :key="indextr" v-for="(row, indextr) in data">
          <vs-td :data="col" v-for="(col, k) in row" :key="k">
            {{ col }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>

export default {
  name: "data-table",
  data(){
    return {
    }
  },
  props: {
    headerFields: null,
    data: null,
    caption: null
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.data-table {
  min-height: 400px;
  margin-bottom: 80px;
  .vs-table {
    border-collapse: collapse;
  }
  .table-caption {
    display: block !important;
    width: 100%;
    padding: 15px 0 15px 20px;
    font-size: 22px;
    color: $white;
    background-color: $green;
    font-weight: 700;
  }
  .vs-con-tbody {
    background: none;
    border: none;
  }
  .vs-table--thead {
    position: static;
  }
  .vs-table--tbody {
    .vs-table--tbody-table .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
      transform: none;
    }
    th {
      border: 1px solid $green;
      font-size: 16px;
      color: $lightGreen;
    }
    td {
      border: 1px solid $green;
      font-size: 16px;
    }
  }
}
</style>
