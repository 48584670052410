<template>
  <div class="user-menu">
    <ul class="user-profile-menu">
      <router-link to="/user/profile/details" v-slot="{ href, route, navigate, isActive, isExactActive }" custom>
        <li :class="[isActive && 'active-menu-item', isExactActive && 'active-menu-item', 'user-profile-menu-item']" @click="navigate">
          <a :href="href" @click="navigate">Personal Information</a>
        </li>
      </router-link>

      <router-link to="/user/profile/preferences" v-slot="{ href, route, navigate, isActive, isExactActive }" custom>
        <li :class="[isActive && 'active-menu-item', isExactActive && 'active-menu-item', 'user-profile-menu-item']" @click="navigate">
          <a :href="href" @click="navigate">Your Preferences</a>
        </li>
      </router-link>

      <router-link to="/user/profile/appointments-schedules" v-slot="{ href, route, navigate, isActive, isExactActive }" custom>
        <li :class="[isActive && 'active-menu-item', isExactActive && 'active-menu-item', 'user-profile-menu-item']" @click="navigate">
          <a :href="href" @click="navigate">Doctor Appointments Schedules</a>
        </li>
      </router-link>

<!--      <router-link to="/user/profile/medical-history" v-slot="{ href, route, navigate, isActive, isExactActive }" custom>-->
<!--        <li :class="[isActive && 'active-menu-item', isExactActive && 'active-menu-item', 'user-profile-menu-item']" @click="navigate">-->
<!--          <a :href="href" @click="navigate">Medical History</a>-->
<!--        </li>-->
<!--      </router-link>-->

      <router-link to="/user/profile/maternity-class-schedules" v-slot="{ href, route, navigate, isActive, isExactActive }" custom>
        <li :class="[isActive && 'active-menu-item', isExactActive && 'active-menu-item', 'user-profile-menu-item']" @click="navigate">
          <a :href="href" @click="navigate">Maternity Class Schedules</a>
        </li>
      </router-link>

<!--      <router-link to="/user/profile/test" v-slot="{ href, route, navigate, isActive, isExactActive }" custom>-->
<!--        <li :class="[isActive && 'active-menu-item', isExactActive && 'active-menu-item', 'user-profile-menu-item']" @click="navigate">-->
<!--          <a :href="href" @click="navigate">Rewards & Points</a>-->
<!--        </li>-->
<!--      </router-link>-->

      <li :class="['user-profile-menu-item log-out-menu-item']" @click="logout">
        <a @click="logout">LOG OUT</a>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  data() {
    return {
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout');
    }
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";
.user-menu {
  margin-left: 20px;
  .user-profile-menu {
    .user-profile-menu-item {
      padding-bottom: 15px;
      a {
        color: $green;
        text-decoration: underline;
        &:hover {
          cursor: pointer;
          text-decoration: none;
          font-weight: 700;
        }
      }
      &.active-menu-item {
        a {
          text-decoration: none;
          font-weight: 700;
        }
      }
      &.log-out-menu-item {
        padding-top: 20px;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}
</style>
