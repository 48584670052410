<template>
  <div class="user-profile-container">
    <section class="user-appointments">
      <div class="user-appointments-info">
          <div class="appointment-schedules">
            <div class="pregnancy-status">
              You’re now at Trimester 2
            </div>
            <div class="pregnancy-week">
              Week 22
            </div>
            <div class="pregnancy-info">
              It’s getting crowded in there! Now that you’re 22 weeks pregnant, your rapidly growing baby is
              invading your space. At this stage, baby is the size of a coconut. Average baby size at 22 weeks
              is 10.9 inches long (at 22 weeks pregnant, baby’s size is measured from the crown of the head to
              the heel of the foot) and 15.2 ounces.
            </div>
            <div class="pregnancy-stage-container">
              <div class="pregnancy-stage-title">
                You’re at this stage
              </div>
              <div class="pregnancy-stages">
                <div class="pregnancy-stage pregnancy-previous-stage">
                  <img src="../../assets/images/pages/user-profile/pregnancy-stage-previous.png"/>
                </div>
                <div class="pregnancy-stage pregnancy-current-stage">
                  <img src="../../assets/images/pages/user-profile/pregnancy-stage-current.png"/>
                </div>
                <div class="pregnancy-stage pregnancy-next-stage">
                  <img src="../../assets/images/pages/user-profile/pregnancy-stage-next.png"/>
                </div>
              </div>
            </div>
            <div class="check-up-info">
              It’s time to check up your baby!
            </div>
            <div class="appointment-schedules">
              <data-table :data="appointmentSchedules" :headerFields="appointmentHeaderFields" caption="Doctor Appointment Schedules"></data-table>
            </div>
          </div>
      </div>
      <profile-menu></profile-menu>
    </section>
  </div>
</template>

<script>

import ProfileMenu from '@/components/user/menu/ProfileMenu.vue';
import DataTable from "@/views/table/DataTable.vue";

export default {
  data() {
    return {
      appointmentHeaderFields: [
        'Date',
        'Time',
        'Tracking ID',
        'Notes',
        'Status'
      ],
      classesHeaderFields: [
        'Date',
        'Time',
        'Tracking ID',
        'Class Name',
        'Status'
      ],
      classesSchedules: [
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          className: "Early Pregnancy Class",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          className: "Preparation For Breastfeeding",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          className: "Early Pregnancy Class",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          className: "Preparation For Breastfeeding",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          className: "Preparation For Breastfeeding",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          className: "Yoga & Pilates Class",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          className: "Yoga & Pilates Class",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          className: "Aqua Antenatal Class",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          className: "Aqua Antenatal Class",
          status: "Completed",
        }
      ],
      appointmentSchedules: [
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Upcoming",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Upcoming",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Upcoming",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Upcoming",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Upcoming",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Completed",
        },
        {
          date: "30/07/20",
          time: "09:00 AM",
          trackingId: "77208267",
          notes: "First visit - 4 weeks pregnancy",
          status: "Completed",
        }
      ]
    }
  },
  components: {
    ProfileMenu,
    DataTable,
  },
  methods: {
  },
  computed: {
    currentUser () {
      return this.$store.state.AppActiveUser
    }
  },
  created() {

  }
}
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";

.user-profile-container {
  max-width: 1400px;
  margin: 0 auto;
  .user-appointments {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 35px;
    .user-appointments-info {
      padding-right: 50px;
      width: 70%;
      .appointment-schedules {
        .pregnancy-status {
          margin: 20px 0 5px 0;
          color: $green;
          font-size: 22px;
          font-weight: 700;
        }
        .pregnancy-week {
          margin-bottom: 15px;
          color: $lightGreen;
          font-size: 18px;
        }
        .pregnancy-info {
          color: $lightGreen;
        }
        .pregnancy-stage-container {
          display: flex;
          flex-direction: column;
          .pregnancy-stage-title {
            margin: 40px 0 5px 0;
            align-self: center;
            color: $green;
            font-weight: 700;
          }
          .pregnancy-stages {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin: 20px 50px 50px 50px;
            .pregnancy-stage {
              width: 50%;
              max-width: 180px;
              img {
                max-width: 100%;
                max-height: 100%;
              }
            }
            @media (max-width: 1200px) {
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
        .check-up-info {
          margin-bottom: 30px;
          color: $lightGreen;
          font-size: 18px;
        }
      }
    }
    .user-menu {
      width: 30%;
    }
  }
}

</style>
